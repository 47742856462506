import CricketStars from "../../public/game-assets/free-cricket-game-online-logo.jpg";
import KnifeDash from "../../public/game-assets/free-flippy-knife-hit-game.jpg";
import MeteorAttack from "../../public/game-assets/free-space-meteor-game-online.jpg";
import StrikeBalls from "../../public/game-assets/strike-ball-shooting-game-online.jpg";
import HighTower from "../../public/game-assets/high-tower-bounce-game-online.jpg";
import FusionBlock from "../../public/game-assets/free-tetris-online-game-logo.jpg";
import FlyingBird from "../../public/game-assets/flappy-bird-game-online-logo.jpg";
import RunoutChamp from "../../public/game-assets/free-runout-cricket-game-online.jpg";
import ChopEmAll from "../../public/game-assets/free-fruitninja-game-online-logo.jpg";
import LudoMatka from "../../public/game-assets/free-classic-ludo-game-online.jpg";
import Im2048 from "../../public/game-assets/free-2048-game-online-logo.jpg";
import Hextris from "../../public/game-assets/hextris-color-puzzle-game-logo.jpg";
import EmojiMineSweeper from "../../public/game-assets/emoji-minesweeper-game-online.jpg";
import Sudo from "../../public/game-assets/sudo-tile-block-puzzle-game.jpg";
import TowerStack from "../../public/game-assets/tower-stack.png";
import CallBreak from "../../public/game-assets/free-callbreak-game-online-logo.jpg";
import HillClimb from "../../public/game-assets/free-hill-climb-game-online.jpg";
import PacMan from "../../public/game-assets/free-pac-man-game-online.jpg";
import BasketHop from "../../public/game-assets/free-basketball-game-online-logo.jpg";
import BallPro from "../../public/game-assets/8-ball-pool-online-logo.jpg";
import Zombvival from "../../public/game-assets/zombie-attack-game-online-logo.jpg";
import SpaceInvader from "../../public/game-assets/space-invader-game-online-logo.jpg";
import EpicQuiz from "../../public/game-assets/free-trivia-online-quiz-logo.jpg";
import TrafficRider from "../../public/game-assets/traffic-rider-icon.jpg";
import EpicChess from "../../public/game-assets/free-chess-game-online-logo.jpg";
import SnakeLadder from "../../public/game-assets/snake-and-ladder.jpg";
import SpaceInvaderBanner from "../../public/game-assets/banners/space-shooter-game-online-banner.jpg";
import EpicQuizBanner from "../../public/game-assets/banners/trivia-quiz-game-online-banner.jpg";
import TrafficRiderBanner from "../../public/game-assets/banners/traffic-rider.jpg";
import ZombvivalBanner from "../../public/game-assets/banners/free-zombie-attack-game-online.jpg";
import BallProBanner from "../../public/game-assets/banners/8-ball-pool-game-banner.jpg";
import CricketStarsBanner from "../../public/game-assets/banners/free-online-cricket-game-banner.jpg";
import KnifeDashBanner from "../../public/game-assets/banners/flippy-knife-hit-game-online.jpg";
import MeteorAttackBanner from "../../public/game-assets/banners/free-galaxy-meteor-attack-game.jpg";
import StrikeBallsBanner from "../../public/game-assets/banners/strike-ball-shooting-game-online.jpg";
import HighTowerBanner from "../../public/game-assets/banners/high-tower-jump-game-banner.jpg";
import FusionBlockBanner from "../../public/game-assets/banners/fusion-blocks.png";
import FlyingBirdBanner from "../../public/game-assets/banners/flappy-bird-game-online-banner.jpg";
import RunoutChampBanner from "../../public/game-assets/banners/runout-cricket-game-online-banner.jpg";
import ChopEmAllBanner from "../../public/game-assets/banners/chop-em-all.webp";
import LudoMatkaBanner from "../../public/game-assets/banners/classic-ludo-game-online-banner.jpg";
import Im2048Banner from "../../public/game-assets/banners/2048-game-online-number-puzzles.png";
import HextrisBanner from "../../public/game-assets/banners/hextris-colour-puzzle-game-online.png";
import EmojiMineSweeperBanner from "../../public/game-assets/banners/emoji-min.png";
import SudoBanner from "../../public/game-assets/banners/sudo-block-puzzle-game-online.png";
import TowerStackBanner from "../../public/game-assets/banners/high-tower-jump-game-online.jpg";
import EpicChessBanner from "../../public/game-assets/banners/free-chess-game-online-banner.jpg";
import SnakeLadderBanner from "../../public/game-assets/banners/snake-and-ladder-game-online.jpg";
import CallBreakBanner from "../../public/game-assets/banners/callbreak-card-game-online-banner.jpg";
import HillClimbBanner from "../../public/game-assets/banners/hill-climb-racing-game-online.jpg";
import PacManBanner from "../../public/game-assets/banners/pac-man-game-online-banner.jpg";
import BasketHopBanner from "../../public/game-assets/banners/basket-ball-game-online-banner.jpg";
import EPL from "../../public/game-assets/free-ipl-cricket-game-online.jpg";
import EPLBanner from "../../public/game-assets/banners/ipl-cricket-game-online-banner.webp";
import NFSBanner from "../../public/game-assets/banners/nfs-banner.jpg";
import NFS from "../../public/game-assets/nfs-logo.png";
import {
  ARCADE,
  BOARD,
  CARD,
  FUN,
  PUZZLE,
  RACING,
  SHOOTER,
  SPORTS,
} from "./categories";
import { OG_IMAGE_BASE_URL } from "constants/links.const";

const GAME_BASE_URL =
  // process.env.NEXT_PUBLIC_GAME_BASE_URL ||
  "https://cfcdn.epicplay.in/epiplay-builds/production/";

export const AppsData = [
  {
    // Space Invader
    type: "unityVertical",
    id: 23,
    game_id: "space_invader",
    categories: [ARCADE, SHOOTER],
    title: "Space Invader",
    description:
      "Space Invader is a thrilling space shooter game that puts you in control of a powerful spaceship, challenging you to take on waves of enemy spaceships and asteroids in an open space environment. If you're a fan of action-packed games and love the excitement of exploring space and fighting battles, you're sure to enjoy the thrilling gameplay of Space Invader. Whether playing on mobile or PC, you will enjoy exploring the galaxy and blasting your way to victory!",
    pathname: "space-invader",
    imageUrl: SpaceInvader,
    imageAlt: "Space Invader Game",
    bannerImageUrl: SpaceInvaderBanner,
    gameUrl:
      "https://cfcdn.epicplay.in/epiplay-builds/production/games-ep/SpaceInvasionV2/index.html",
    meta: {
      keywords: "space game, space invader, space battle, space wars",
      title: "Space Invader: Play Epic Space Shooter Game Free Online",
      description:
        "Play the epic space shooter game 'Space Invader' online for free! Defend your spaceship, shoot meteors, and conquer the galaxy war. Join the battle now!",
      image: OG_IMAGE_BASE_URL + "space-shooter-game-online-og.jpg",
    },
  },
  {
    // Chop'em All
    id: 9,
    game_id: "chop_em_all",
    title: "Chop'em All",
    categories: [ARCADE],
    description:
      "Be the ninja in the ultimate fruit-cut game! Avoid slicing bombs while you’re trying to slice more fruits. Score more points with each slice and enjoy the single-player game in three exciting modes. What are you waiting for? Get ready for a thrilling gameplay experience in the best fruit slice game! ",
    pathname: "chop-em-all",
    imageUrl: ChopEmAll,
    imageAlt: "FruitNinja: Play Fruit Cut Game Online",
    bannerImageUrl: ChopEmAllBanner,
    gameUrl: GAME_BASE_URL + "games-ep/ChopEmAllV9/index.html",
    meta: {
      title: "Chop'em All: Play Fruit Cut Game Online for Free | FruitNinja",
      description:
        "Join the fruit frenzy with Chop'em All! Play this exciting fruit cut game online and test your reflexes as you chop the fruits by slicing them. Play Now!",
      keywords: "fruit cut game, fruit slice game, fruit game, FruitNinja",
      image: OG_IMAGE_BASE_URL + "fruit-ninja-game-online-og.jpg",
    },
  },
  {
    // Knife Dash
    id: 2,
    game_id: "knife_dash",
    title: "Knife Dash",
    // ws: {
    //   gid: "knife_dash",
    //   gv: "0.21",
    //   mde: "knife_dash_v1",
    // },
    categories: [FUN],
    description:
      "Knife Dash is a simple and exciting flippy knife game that allows you to target and break woody blocks. Every broken wood will gain points and knife’s hitting with rock will end the game. The longer you keep breaking the wood blocks, the higher distance range you record. Note that breaking the Pinatas gets you extra 10 points, however, these pinatas only break when you hit them 5 times. Pinata’s occurrence will be spawned after every 100m.",
    pathname: "knife-dash",
    controls: "Tap on the screen to balance the knife and move forward.",
    imageUrl: KnifeDash,
    imageAlt: "Knife Dash: knife hit game",
    bannerImageUrl: KnifeDashBanner,
    gameUrl:
      "https://cfcdn.epicplay.in/epiplay-builds/production/games-ep/KnifeDashV23/index.html",
    meta: {
      title: "Knife Dash - Flippy Knife Game Online, Play Flip Game For Free",
      description:
        "Flippy knife game online that's both challenging and fun. Enjoy Knife Dash flip game for free. Play now!",
      keywords: "flip game, knife game, flippy game online, knife dash",
      image: OG_IMAGE_BASE_URL + "flippy-knife-hit-game-online-og.jpg",
    },
  },

  {
    // EPL - Cricket Stars
    isLeaderboard: true,
    id: 28,
    game_id: "epl",
    title: "EPL",
    ws: {
      gid: "epl",
      gv: "1.0.0",
      mde: "epl_v1",
    },
    tips: [
      "Compete with players across the globe & make your team win",
      "Every run you score is added to your team’s scoreboard",
      "Hit 3 sixes to gain a fire-bat",
    ],
    categories: [SPORTS],
    description:
      "Cricket Star is a depiction of a real cricket match that allows players to experience the fun and thrill like playing in the ground with the comfort of resting on the couch. As a batsman, players attempt to  make high scores, hit powerful shots, and create a streak to hit powerful shots. Also, cheerleaders will be on your support to cheer up the players.",
    pathname: "ipl-cricket-game",
    imageUrl: EPL,
    imageAlt: "EPL: ipl cricket game",
    bannerImageUrl: EPLBanner,
    gameUrl:
      "https://cfcdn.epicplay.in/epiplay-builds/production/games-ep/EplCricket_V39/index.html",
    meta: {
      title: "Epic Premier League (EPL) - Best Free IPL Cricket Game",
      description:
        "Enjoy the excitement of IPL cricket on your PC or on your mobile with Epic Premier League (EPL). The best online IPL cricket game. Play now for free!",
      keywords:
        "ipl cricket game, best ipl game, ipl games online, ipl game, ipl mobile games",
      image: OG_IMAGE_BASE_URL + "ipl-cricket-game-online-og.jpg",
    },
  },

  {
    // Epic Chess
    id: 16,
    game_id: "epic_chess",
    title: "Epic Chess",
    categories: [BOARD],
    description:
      "Here is the ultimate online chess game! Chess is a two-player board game that needs strategic skills to solve. One player is team White and the other is team Black. Both players play with an aim to Checkmate the opponent’s King. When this is done, the game is over. Play with friends (offline) or practice with the AI bot and up your chess game!",
    pathname: "epic-chess",
    imageUrl: EpicChess,
    imageAlt: "Epic Chess: play chess online",
    bannerImageUrl: EpicChessBanner,
    gameUrl: GAME_BASE_URL + "games-ep/EpicChessV9/index.html",
    meta: {
      title: "Play Epic Chess: Free Online Chess Game Against Computer",
      description:
        "Play Epic Chess an exciting online chess game against the computer or with friends on the same computer for free without registering. Start Playing Now!",
      image: OG_IMAGE_BASE_URL + "free-chess-game-online-banner-og.jpg",
    },
  },
  {
    id: 25,
    game_id: "snake_and_ladder",
    title: "Snake and Ladder",
    description:
      "Snake and Ladder is a classic board game that has been enjoyed by generations. It is a game of chance and strategy that can be played by two or more players. The objective of the game is to reach the finish line by moving a game piece along a board that is marked with a grid of squares. The board features snakes and ladders that can either help or hinder the player's progress.",
    pathname: "snake-and-ladder",
    imageUrl: SnakeLadder,
    imageAlt: "Snake and Ladder Game",
    gameUrl:
      "https://cfcdn.epicplay.in/epiplay-builds/production/games-ep/SnakeAndLadderV1/index.html",
    categories: [BOARD],
    bannerImageUrl: SnakeLadderBanner,
    meta: {
      keywords:
        "snakes and ladders, sap sidi game, snakes and ladders board, play snake and ladder",
      title:
        "Snakes and Ladders Game Online Board Game | Sap Sidi Online For FREE",
      description:
        "Play snakes and ladders online FREE. Cherish your childhood by playing snakes and ladders, sap sidi game on desktop, pc, mobile anytime.",
      image: OG_IMAGE_BASE_URL + "snake-and-ladder-game-online-og.jpg",
    },
  },
  {
    // Ludo Matka
    id: 10,
    game_id: "ludo_matka",
    title: "Ludo Matka",
    categories: [BOARD],
    description:
      "Ludo Matka is a fun and adventurous Ludo game online developed by EpicPlay on the Unity Engine. To fully amaze the players, this game supports “Solo”, “Multiplayer” and “Play with Friends”. Each player has 4 pawns running clockwise from start to end zone, heading off to the home column where they are safe against other players.",
    pathname: "ludo-matka",
    imageUrl: LudoMatka,
    imageAlt: "Ludo Matka: play ludo online",
    bannerImageUrl: LudoMatkaBanner,
    gameUrl: GAME_BASE_URL + "games-ep/LudoMatkaV15/index.html",
    meta: {
      keywords:
        "ludo, play ludo, ludo online, classic ludo, ludo matka, ludo multiplayer",
      title:
        "Ludo Matka - Play Ludo Game Online, Classic Ludo Multiplayer For Free",
      description:
        "Classic Ludo Matka for FREE! Enjoy 3 modes - ✓Solo ✓Multiplayer ✓Play with Friends. No Download Needed!!",
      image: OG_IMAGE_BASE_URL + "classic-ludo-game-online-banner-og.jpg",
    },
  },
  {
    // Fusion Blocks
    id: 5,
    game_id: "fusion_block",
    title: "Fusion Blocks",
    categories: [PUZZLE],
    description:
      "Inspired by the well-known Tetris, Fusion Blocks is one of the best block games online. It is a simple puzzle game you can play at leisure. Arrange the blocks in a straight line to clear the grid. Try not to leave gaps to avoid losing. Gain points every time you clear the grid! Exercise your brain by trying this fun building blocks game.",
    controls:
      "Drag blocks to move them and Try to create a straight line on the grid vertically or horizontally.",
    pathname: "fusion-blocks",
    imageUrl: FusionBlock,
    imageAlt: "Fusion Blocks: block game",
    bannerImageUrl: FusionBlockBanner,
    gameUrl: GAME_BASE_URL + "games-ep/FusionBlocksV12/index.html",
    meta: {
      title:
        "Fusion Blocks - Block Puzzle Game Online, Play Tetris Game For Free",
      description:
        "Amazing new variant of tetris game online available for FREE! Enjoy this time-killer block puzzle game online on PC, Mobile Browser anytime.",
      keywords: "block puzzle game, tetris, free block game, fusion blocks",
      image: OG_IMAGE_BASE_URL + "block-tetris-game-online-banner-og.jpg",
    },
  },
  {
    type: "quiz",
    id: 28,
    game_id: "epic_quiz",
    title: "Epic Quiz",
    description:
      "Welcome to Epic Quiz: free online quiz game! If you love trivia and enjoy testing your knowledge, you've come to the right place. Our quiz game features a variety of questions that cover a wide range of topics, including history, science, geography, pop culture, and more. Whether you want to challenge yourself or play with friends, our quiz game is a fun and engaging way to spend your time.",
    pathname: "epic-quiz",
    imageUrl: EpicQuiz,
    categories: [FUN],
    imageAlt: "Epic Quiz Game",
    bannerImageUrl: EpicQuizBanner,
    meta: {
      keywords:
        "quiz questions, quiz game, trivia game, online quiz, fun trivia",
      title:
        "Quiz Questions Game - Trivia Quiz Questions & Answers, Online Quiz Games",
      description:
        "Play quiz questions game for FREE! Play trivia questions game on PC, desktop, mobile browser from anywhere!",
      image: OG_IMAGE_BASE_URL + "trivia-quiz-game-online-og.jpg",
    },
  },

  {
    // Cricket Stars
    id: 1,
    game_id: "cricket_star",
    title: "Cricket Star",
    // ws: {
    //   gid: "cricket_stars",
    //   gv: "1.0.0",
    //   mde: "cricket_stars_v1",
    // },
    categories: [SPORTS],
    description:
      "Cricket Star is a depiction of a real cricket match that allows players to experience the fun and thrill like playing in the ground with the comfort of resting on the couch. As a batsman, players attempt to  make high scores, hit powerful shots, and create a streak to hit powerful shots. Also, cheerleaders will be on your support to cheer up the players.",
    pathname: "cricket-stars",
    controls:
      "Tap on the screen at the right time to swing the bat and hit a single, double, triple run, or a boundary.",
    imageUrl: CricketStars,
    imageAlt: "Cricket Star: cricket game",
    bannerImageUrl: CricketStarsBanner,
    videoPosition: "60%",
    gameUrl:
      "https://cfcdn.epicplay.in/epiplay-builds/production/games-ep/CricketStarsV40/index.html",

    meta: {
      title: "Cricket Stars - Best Online Cricket Game PC For Free",
      description:
        "Best online cricket game for PC, Desktop & Mobile browser. Enjoy FREE cricket game anytime, anywhere. Let's play!",
      keywords:
        "cricket games, online cricket games, pc cricket games, best cricket games",
      image: OG_IMAGE_BASE_URL + "free-online-cricket-game-og.jpg",
    },
  },
  {
    type: "unityLandscape",
    id: 18,
    game_id: "hillclimb_india",
    title: "Hill Climb India",
    categories: [RACING],
    description:
      "Hill Climb India is an arcade game that will take your hill racing experience to the next level. Drive an Auto or car on a long hilly street with minor bumps and pick and drop passengers along the way. Hill climb India not only lets you enjoy the racing experience but also gives you a nice feeling of physics while driving the vehicle on a hill. Hill climb games have always been the favorite driving entertainment and racing games and this arcade game, Hill Climb Auto Rickshaw Drive Racing game, brings you the best with a unique concept of driving and racing an Auto Rickshaw on hills and offroad.Enjoy the fun and thrill of picking up passengers, driving them through multiple terrains and doing wheelies, keeping the luggage from falling off, and dropping them at the drop point safely - All of this in the given time duration. The game hill climb India, is similar to the hill car race. In this arcade game, you own a Tuk Tuk and want to run a big Cab business. You pick up and drop off passengers and earn money by driving your Auto off-roading on non-stable hills. You need to go or race carefully as you come across bumps, bridges, and hills. Repair your Auto Rickshaw if it is damaged regularly while driving in the Pappu Garage on the way. Pick up the passengers, drop them at their destination, and earn money by dropping them off.",
    pathname: "hill-climb-racing",
    imageUrl: HillClimb,
    imageAlt: "Hill Climb India: driving game",
    bannerImageUrl: HillClimbBanner,
    gameUrl: GAME_BASE_URL + "games-ep/HillClimbIndiaV4_3/index.html",
    meta: {
      keywords: "hill climb racing, hill climb, hill racing",
      title:
        "Hill Climb Racing Game Online - Play Hill Racing Game Online For Free",
      description:
        "Play hill climb game online for Free! Games like hill climb racing is now available to play on PC, desktop & mobile browser",
      image: OG_IMAGE_BASE_URL + "hill-climb-racing-game-online-og.jpg",
    },
  },
  {
    // High Tower
    id: 6,
    game_id: "high_tower",
    title: "High Tower",
    categories: [FUN],
    // ws: {
    //   gid: "high_tower",
    //   gv: "1.0.0",
    //   mde: "high_tower_v1",
    // },
    description:
      "High tower is a fun and exciting game designed with the popular real-time 3D, AR & VR game development platform, Unity. The game allows you to play across the deadly traps of the tower with a cute character that jumps with a tap on the screen and bounces off walls. It keeps jumping and reaching high as long as you keep tapping the screen. If you miss tapping the screen while the game character is walking across the tower, then it will fall off and your game will end. The longer you survive, the higher you score. ",
    pathname: "high-tower",
    controls:
      "Tap on the screen to make the character jump. Bump on the walls to change directions.",
    imageUrl: HighTower,
    imageAlt: "High Tower: tower game",
    bannerImageUrl: HighTowerBanner,
    videoPosition: "80%",
    gameUrl:
      "https://cfcdn.epicplay.in/epiplay-builds/production/games-ep/HighTowerV24/index.html",
    meta: {
      title:
        "High Tower - Jump & Bounce Game Online, Tower Climb Game For Free",
      description:
        "Enjoy this marvellous tower climbing game online for free. This is a fun game to jump and bounce to score high. Play Now!",
      keywords: "jump game, bounce game, tower game, climb game, high tower",
      image: OG_IMAGE_BASE_URL + "high-tower-jump-game-og.jpg",
    },
  },
  {
    // 2048
    type: "h5",
    id: 11,
    game_id: "2048",
    title: "2048",
    categories: [PUZZLE],
    description:
      "One of the most popular number puzzle games online, 2048 is both tricky and terrific! Whether you want to test your strategic skills or just want a temporary distraction, this game is the absolute option. Play the number puzzle game today and achieve your play target!",
    pathname: "2048",
    imageUrl: Im2048,
    imageAlt: "2048: number puzzle game",
    bannerImageUrl: Im2048Banner,
    gameUrl: GAME_BASE_URL + "games-ep/2048_V4/index.html",
    meta: {
      keywords: "2048 game, 2048 online, 2048 puzzle, 2048 number game",
      title: "2048 Game - Play 2048 Number Puzzle Game Online For Free",
      description:
        "Do you love brain games? Play 2048 number puzzle online for FREE on PC, Mobile Browser now!",
      image: OG_IMAGE_BASE_URL + "2048-game-online-number-puzzles-og.jpg",
    },
  },

  {
    // Strike Balls
    id: 4,
    game_id: "strike_balls",
    title: "Strike Balls",
    categories: [SHOOTER],
    description:
      "One of the best free online ball games, play the Strike Balls game and bust your stress. Knock down juice cans with a ball. Judge the distance and direction of the cans before flicking the ball towards them. Strike more cans in each try and beat your own score at your own pace. Avoid striking bombs disguised as juice cans.",
    pathname: "strike-balls",
    controls: "Flick the ball to throw it toward the can.",
    imageUrl: StrikeBalls,
    imageAlt: "Strike Balls: ball shooting game",
    bannerImageUrl: StrikeBallsBanner,
    gameUrl: GAME_BASE_URL + "games-ep/StrikeBallsV16/index.html",
    meta: {
      title: "Strike Balls - Shooting Ball Game Online, Strike Game For Free",
      description:
        "Play an amazing ball striking time pass game online for free. Kill your boredom and start playing this fun ball game now!",
      keywords: "ball game, strike game, shooting ball, strike balls",
      image: OG_IMAGE_BASE_URL + "strike-ball-shooting-game-online-og.jpg",
    },
  },

  {
    type: "unityLandscape",
    id: 17,
    game_id: "callbreak",
    title: "CallBreak",
    categories: [CARD],
    description:
      "Callbreak is a trick-taking card game played with a standard 52-card deck between four players. There are multiple rounds in a game. The player has to make a bet before starting of each round guessing how many hands they can win, and keep their score highest from all players to win the game.",
    pathname: "callbreak",
    imageUrl: CallBreak,
    imageAlt: "Callbreak: card game",
    bannerImageUrl: CallBreakBanner,
    gameUrl: GAME_BASE_URL + "games-ep/CallbreakClubV14/index.html",
    meta: {
      keywords: "call break, call break multiplayer, lakadi, call break for pc",
      title: "Call Break Multiplayer Game Online - Lakadi Game PC For Free",
      description:
        "Are you a card game lover? Play this exciting new call break multiplayer game on epicplay.in for FREE and enjoy!",
      image: OG_IMAGE_BASE_URL + "callbreak-card-game-online-banner-og.jpg",
    },
  },
  // new games

  {
    // Traffic Rider
    type: "unityVertical",
    id: 24,
    game_id: "traffic_rider",
    title: "Traffic Rider",
    categories: [RACING],
    description:
      "Traffic Rider is a thrilling moto bike game that has taken the world of riding games by storm. This online browser racing game offers an adrenaline-packed experience that keeps players returning for more. Whether you’re looking for a quick and exciting way to pass the time or a more challenging experience, Traffic Rider has it all.",
    pathname: "traffic-rider",
    imageUrl: TrafficRider,
    imageAlt: "Traffic Rider Game",
    bannerImageUrl: TrafficRiderBanner,
    gameUrl:
      "https://cfcdn.epicplay.in/epiplay-builds/production/games-ep/TrafficRiderV14/index.html",
    meta: {
      keywords:
        "traffic rider, bike racing, bike wala game, motorbike racing, moto bike racing",
      title:
        "Traffic Rider - Moto Bike Racing Game, Bike Wala Game Online For PC",
      description:
        "Play traffic rider moto bike game for FREE! Bike racing game available to play for pc, desktop, mobile anytime.",
      image: OG_IMAGE_BASE_URL + "bike-racing-game-online-og.jpg",
    },
  },
  {
    // Flying Bird
    id: 7,
    game_id: "flying_bird",
    title: "Flying Bird",
    // ws: {
    //   gid: "flying_bird",
    //   gv: "1.0.09",
    //   mde: "flying_bird_v1",
    // },
    categories: [ARCADE],
    description:
      "A little yellow bird is trying to escape through the gaps between a messy arrangement of pipes, putting your concentration ability to test.  Challenging your reflexes by helping the bird get through and beat your own score with each try at the flappy Flying Bird game!",
    pathname: "flying-bird",
    imageUrl: FlyingBird,
    imageAlt: "Flying Bird: flappy bird online",
    bannerImageUrl: FlyingBirdBanner,
    gameUrl:
      "https://cfcdn.epicplay.in/epiplay-builds/production/games-ep/FlyingBirdV10/index.html",
    meta: {
      title: "Flying Bird: Free Epic Flappy Bird Game Online Play Now",
      description:
        "Play the classic Flappy Bird game online for free and relive the excitement of the Flying Bird game. Start flapping by clicking to play Flappy Bird now!",
      keywords: "flappy bird, flying bird game, flappy game",
      image: OG_IMAGE_BASE_URL + "flappy-bird-game-online-og.jpg",
    },
  },
  {
    // Hextris
    type: "h5",
    id: 14,
    game_id: "hextris",
    title: "Hextris",
    categories: [PUZZLE],
    description:
      "A discrete version of Tetris is here! We have the ultimate Hextris, a hexagonal shape puzzle game that can instill curiosity in you to ace it. Colorful, intuitive, and super fun to play, Hextris is the absolute pastime for someone with a taste for logic and fun. Play this shape puzzle game that is both simple and engaging.",
    pathname: "hextris",
    imageUrl: Hextris,
    imageAlt: "Hextris: shape puzzle game",
    bannerImageUrl: HextrisBanner,
    gameUrl: GAME_BASE_URL + "games-ep/hextrisV5/index.html",
    meta: {
      keywords: "hextris, shapes puzzle, puzzle game",
      title: "Hextris: Play The Best Ultimate Hexagon Puzzle Game Online",
      description:
        "Play Hextris, the ultimate online hexagon puzzle game inspired by Tetris for desktop or mobile. Match colors, clear lines, and earn points. Play now!",
      image: OG_IMAGE_BASE_URL + "hextris-colour-puzzle-game-online-og.jpg",
    },
  },
  {
    // Meteor Attack
    id: 3,
    game_id: "meteor_attack",
    title: "Meteor Attack",
    categories: [FUN],
    description:
      "Save a rover on an unknown planet from a meteor attack. The rover sent to the planet to collect information moves around when meteors start blasting it. There’s no way but to escape the meteor attack to collect more information. The longer the rover survives, the more information it can send to the space station. Collect shields to protect the rover from one meteor hit each time.",
    controls:
      "Tap on the screen to start moving in a direction around the planet. Tap again to move in opposite directions. Tap repeatedly to balance the rover in place around the planet.",
    pathname: "meteor-attack",
    imageUrl: MeteorAttack,
    imageAlt: "Meteor Attack: space games",
    bannerImageUrl: MeteorAttackBanner,

    gameUrl: GAME_BASE_URL + "games-ep/MeteorAttackV16/index.html",
    meta: {
      title: "Meteor Attack - Play Ultimate Space Game, Tap Game For Free",
      description:
        "Put your reflexes to test, and see if you can save the rover from a meteor attack! Play now for FREE!",
      keywords:
        "meteor attack, space games, galaxy games, tap games, galaxy shooter",
      image: OG_IMAGE_BASE_URL + "free-galaxy-meteor-attack-game-og.jpg",
    },
  },
  {
    // Sudo
    type: "h5",
    id: 13,
    game_id: "sudo",
    title: "Sudo",
    categories: [PUZZLE],
    description:
      "Inspired by Sudoku, Sudo is a block puzzle game that grows on you more each time you play it! There are two types of tiles and three rules to follow. If you break them, you can’t make it to the end. This mind puzzle game puts your thinking ability to the test. Play today to enhance your thinking skills… and, of course for fun!",
    pathname: "sudo",
    imageUrl: Sudo,
    imageAlt: "Sudo: tile game",
    bannerImageUrl: SudoBanner,
    gameUrl: GAME_BASE_URL + "games-ep/sudoV4/index.html",
    meta: {
      keywords: "sudo, tile game, block puzzle, block sudoku",
      title: "Sudo - Tile Game, Block Sudoku Puzzle Game Online For Free",
      description:
        "Play this logic puzzle game inspired by Sudoku. Challenge your thinking ability and follow the rules to win. Play today and enjoy.",
      image: OG_IMAGE_BASE_URL + "sudo-block-puzzle-game-online-og.jpg",
    },
  },
  {
    // Runout Champ
    id: 8,
    game_id: "runout_champ",
    title: "Runout Champ",
    categories: [SPORTS],
    description:
      "Are you a fan of cricket? If yes, Runout Champ is your go-to run out cricket game. You are the fielder who needs to score by hitting the stumps with the ball before the batsman reaches the crease. With in-game tips and three different modes, this new cricket game for PC gets as easy as it can to play and score.",

    pathname: "runout-champ",
    imageUrl: RunoutChamp,
    imageAlt: "Runout Champ: out run game",
    bannerImageUrl: RunoutChampBanner,
    gameUrl: GAME_BASE_URL + "games-ep/RunOutChampV9/index.html",
    meta: {
      title: "Runout Champ - Play Run Out Cricket Game, Stump It Game For Free",
      description:
        "Are you a 'Cricket Fan', don't miss Runout Champ an ultimate stumping game for FREE! This new game is an amazing time pass game you can play anytime.",
      keywords: "run out game, stump it game, runout champ",
      image: OG_IMAGE_BASE_URL + "runout-cricket-game-online-og.jpg",
    },
  },

  {
    // Emoji Minesweeper
    type: "h5",
    id: 15,
    game_id: "emoji_minesweeper",
    title: "Emoji Minesweeper",
    categories: [PUZZLE],
    description:
      "Here is the ultimate Minesweeper game with a simple twist. Discover mines by clicking on cells. Use your thinking ability to figure out the position of the bombs and avoid clicking on them. Beat the timer with each game and finish the game in as few moves as you can. Play minesweeper online for free, the super fun logic-based game!",
    pathname: "emoji-minesweeper",
    imageUrl: EmojiMineSweeper,
    imageAlt: "Emoji Minesweeper game online",
    bannerImageUrl: EmojiMineSweeperBanner,
    gameUrl: GAME_BASE_URL + "games-ep/EmojiMinesweeperV8/index.html",
    meta: {
      keywords: "minesweeper, emoji game, free minesweeper",
      title: "Emoji Minesweeper: Play Minesweeper Game Online Free Now",
      description:
        "Enjoy the classic Minesweeper game online for free on mobile! Uncover squares, avoid hidden mines, and test your strategic skills. Play Minesweeper Online Now!",
      image: OG_IMAGE_BASE_URL + "play-minesweeper-game-online-og.jpg",
    },
  },

  {
    // Pac Man
    type: "unityVertical",
    id: 19,
    game_id: "pac_man",
    title: "Pac Man",
    categories: [ARCADE],
    description:
      "Pac-Man is a classic arcade game that has been enjoyed by players of all ages for decades. The game is simple to learn, but difficult to master. Pac-Man is a classic game that has stood the test of time. It's simple to learn but challenging to master. Give it a try and see if you can beat your high score!",
    pathname: "pac-man",
    imageUrl: PacMan,
    imageAlt: "Pac Man: maze game online",
    bannerImageUrl: PacManBanner,
    gameUrl: GAME_BASE_URL + "games-ep/PacManV1/index.html",
    meta: {
      keywords: "pac man, pacman game, pacman online",
      title: "Pac Man Game - Play Pacman Game Online For Free",
      description:
        "Pac Man game is FREE to play game! Enjoy most fun and exiting pacman game on your desktop, pc, mobile browser anytime.",
      image: OG_IMAGE_BASE_URL + "pac-man-game-online-og.jpg",
    },
  },
  {
    // Basket Hop
    type: "unityVertical",
    id: 20,
    game_id: "basket_hop",
    title: "Basket Hop",
    categories: [SPORTS],
    // ws: {
    //   gid: "basket_hop",
    //   mde: "basket_hop_v1",
    //   gv: "1.0.4",
    // },
    description:
      "Basket Hop is a mobile game that involves bouncing a basketball on a moving hoop. It is a simple yet engaging game that can be enjoyed by basketball fans of all ages. Give it a try and see how many points you can score. The objective of the game is to score as many points as possible by bouncing the basketball into the hoop. The game features various obstacles such as spinning circles or moving platforms that add to the challenge.",
    pathname: "basket-hop",
    imageUrl: BasketHop,
    imageAlt: "Basket Hop: basket ball game",
    bannerImageUrl: BasketHopBanner,
    gameUrl:
      "https://cfcdn.epicplay.in/epiplay-builds/production/games-ep/Basket_HopV7/index.html",
    meta: {
      keywords: "basketball game, basketball hop",
      title: "Basketball Game - Basketball Hop Game Online For Free",
      description:
        "Play basket ball game online for FREE. Enjoy basket hop online game on your desktop, pc, mobile anytime.",
      image: OG_IMAGE_BASE_URL + "basket-ball-game-online-banner-og.jpg",
    },
  },
  {
    // 8 Ball Pool
    type: "unityLandscape",
    id: 21,
    game_id: "8_ball_pool",
    categories: [BOARD, SPORTS],
    title: "8 Ball Pool",
    description:
      "8 ball pool is a popular cue sport played with a cue ball and 15 object balls numbered 1 through 15. The objective of the game is to pocket all of the object balls followed by the 8 ball. The player who legally pockets the 8 ball after pocketing all of their object balls wins the game.",
    pathname: "8-ball-pool",
    imageUrl: BallPro,
    imageAlt: "8 Ball Pool: pool game online",
    bannerImageUrl: BallProBanner,
    gameUrl:
      "https://cfcdn.epicplay.in/epiplay-builds/production/games-ep/8BallPoolV06/index.html",
    meta: {
      keywords: "8 ball pool, 8 ball game, online pool, 8 pool, 8 ball pool pc",
      title: "8 Ball Pool Game Online Play On PC And Mobile For Free",
      description:
        "If you want to play 8 Ball Pool game online, join the fun for free on PC & mobile. Challenge your friends in this immersive 8 ball pool multiplayer game now!",
      image: OG_IMAGE_BASE_URL + "8-ball-pool-game-og.jpg",
    },
  },
  {
    // Zombvival
    type: "unityVertical",
    id: 22,
    categories: [SHOOTER],
    game_id: "zombvival",
    title: "Zombvival",
    description:
      "Zombvival is the ultimate game for fans of zombie games and zombie survival games on PC and mobile. In this game, players must survive by fighting off waves of zombies in a world devastated by a zombie apocalypse. The game involves navigating through dangerous areas filled with different types of zombies. At the beginning of the game, players have a basic weapon - a knife but can unlock more weapons as they progress through the game. Players can also collect skulls to make progress through levels and health packs to help them survive longer.",
    pathname: "zombvival",
    imageUrl: Zombvival,
    imageAlt: "Zombvival: zombie survival game",
    bannerImageUrl: ZombvivalBanner,
    gameUrl:
      "https://cfcdn.epicplay.in/epiplay-builds/production/games-ep/ZombVivalV2/index.html",
    meta: {
      keywords: "zombie game, zombie shooter, zombie attack, zombie survivals",
      title: "Zombie Game - Play Zombie Shooter, Zombie Survival Online For PC",
      description:
        "Play zombie shooter game for FREE! This zombie shooter game can be played on pc, desktop, mobile anytime.",
      image: OG_IMAGE_BASE_URL + "free-zombie-attack-game-online-og.jpg",
    },
  },

  {
    id: 31,
    game_id: "need_for_speedsters",
    title: "Need for Speedsters",
    description:
      "Get ready to play the best car racing game online! Experience the thrill of 3D car games and become the ultimate racer. Play Need For Speedsters now!",
    categories: [RACING],
    pathname: "car-racing-game",
    imageUrl: NFS,
    imageAlt: "Need for Speedsters Game",
    gameUrl: GAME_BASE_URL + "games-ep/EpicNFS_V2/index.html",
    bannerImageUrl: NFSBanner,
    meta: {
      keywords:
        "car racing game, racing games, car games, online racing game, 3d car games, car games to play, free car games, best racing games, car racing games online, best car games",
      title: "Need for Speedsters: Best Online Car Racing Game For Free",
      description:
        "Get ready to play the best car racing game online! Experience the thrill of 3D car games and become the ultimate racer. Play Need For Speedsters now!",
      image: OG_IMAGE_BASE_URL + "free-car-racing-game-online-og.jpg",
    },
  },
];
