import AllGamesIcon from "public/game-assets/categories/all-games.svg";
import PuzzleIcon from "public/game-assets/categories/Puzzle.svg";
import FunIcon from "public/game-assets/categories/Fun.svg";
import BoardIcon from "public/game-assets/categories/Board.svg";
import SportsIcon from "public/game-assets/categories/Sports.svg";
import ArcadeIcon from "public/game-assets/categories/Arcade.svg";
import ShootingIcon from "public/game-assets/categories/Shooting.svg";
import RacingIcon from "public/game-assets/categories/Racing.svg";
import CardIcon from "public/game-assets/categories/Card.svg";
import { makeMap } from "utils/index";

export const PUZZLE = "PUZZLE"; //
export const FUN = "FUN"; //
export const BOARD = "BOARD";
export const SPORTS = "SPORTS"; //
export const ARCADE = "ARCADE"; //
export const SHOOTER = "SHOOTER";
export const CARD = "CARD";
export const RACING = "RACING";

/**
 * An array containing objects representing game categories.
 *
 * @typedef {Object} Category
 * @property {string} id - The ID of the category.
 * @property {string} name - The name of the category.
 * @property {string} icon - The path to the icon representing the category.
 * @property {string} pathname - The pathname associated with the category.
 * @property {string} description - The description of the category.
 */

/**
 * An array of game categories.
 *
 * @type {Category[]}
 * @param {string} path - The path to match.
 * @returns {string} The ID of the category.
 */
const CATEGORIES = [
  {
    id: PUZZLE,
    name: "Puzzle",
    icon: PuzzleIcon,
    pathname: "puzzle-games",
    description: "Our collection of free online puzzle games",
    meta: {
      title: "Play The Best Free Online Puzzle Games On Mobile & PC",
      description: "Challenge yourself with best puzzle games online! Try our different types of puzzle games including jigsaw puzzles, number puzzles, and more. Play now!",
    },
  },
  {
    id: FUN,
    name: "Fun",
    icon: FunIcon,
    pathname: "fun-games",
    description: "Our collection of free online fun games",
    meta: {
      title: "Play The Best Free Online Fun Games On Mobile & PC",
      description: "Looking for some fun games to play with friends and family? Check out our collection of funny games that are sure to make you smile. Play now for free!",
    },
  },
  {
    id: BOARD,
    name: "Board",
    icon: BoardIcon,
    pathname: "board-games",
    description: "Our collection of free online board games",
    meta: {
      title: "Play The Best Free Online Board Games On Mobile & PC",
      description: "Looking for fun board games to play with friends and family? Check out our top collection of classic and modern board games. Start playing now for free!",
    },
  },
  {
    id: SPORTS,
    name: "Sports",
    icon: SportsIcon,
    pathname: "sports-games",
    // title: "Play Sports Games Online On Mobile & PC",
    description: "Our collection of free online sports games",
    meta: {
      title: "Play The Best Free Online Sports Games On Mobile & PC",
      description: "Looking for the Best Free Online Sports Games? We got you covered! Browse our wide range of cricket games basket games and more. Play now for free!",
    },
  },
  {
    id: ARCADE,
    name: "Arcade",
    icon: ArcadeIcon,
    pathname: "arcade-games",
    description: "Our collection of free online arcade games",
    meta: {
      title: "Play Best Free Online Arcade Games On Mobile & PC",
      description: "Looking for some arcade game action? Play the best arcade games online and experience the excitement of arcade gaming like never before. Play now for free!",
    },
  },
  {
    id: SHOOTER,
    name: "Shooter",
    icon: ShootingIcon,
    pathname: "shooter-games",
    description: "Our collection of free online shooter games",
    meta: {
      title: "Play Best Free Online Shooting Games On Mobile & PC",
      description: "Step into the world of shooter games and prove your mastery. Play for free on EpicPlay and become the ultimate sharpshooter. Ready, aim, fire!",
    },
  },
  {
    id: CARD,
    name: "Card",
    icon: CardIcon,
    pathname: "card-games",
    description: "Our collection of free online card games",
    meta: {
      title: "Play Best Free Online Card Games On Mobile & PC",
      description: "Looking for the best free card games? Check out our top-rated collection of free solitaire and card games for friends and family. Join now and start playing!",
    },
  },
  {
    id: RACING,
    name: "Racing",
    icon: RacingIcon,
    pathname: "racing-games",
    description: "Our collection of free online racing games",
    meta: {
      title: "Play Best Free Online Racing Games On Mobile & PC",
      description: "Looking for the Best Free Online Racing Games? We got you covered! Browse our wide range of 🏎️car racing and 🏍️bike racing games. Play now for free!",
    },
  },
];

export function getCategoryKeyFromPath(path) {
  return CATEGORIES.find(category => category.pathname === path)?.id;
}

export { CATEGORIES };
export const CategoriesMap = new Map(
  CATEGORIES.map(category => [category.id, category])
);
// export const PathCat = makeMap(CATEGORIES, () => 'path-name');
