import React from "react";
import { Transition } from "@headlessui/react";
function FadeTransition({ children, show = true }) {
  return (
    // sliding in from the right
    <Transition
      appear={true}
      show={show}
      //   scale from 0 to 1, opacity from 0 to 1
      enter="transition-opacity duration-500"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-500"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      {children}
    </Transition>
  );
}

export default FadeTransition;
