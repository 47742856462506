import React from "react";
import classes from "./styles.module.scss";
import clsx from "clsx";

/**
 * @typedef {'title' | 'titleNoMargin' | 'subTitle' | 'normal'} Variant
 * @description The available variants for the Heading component.
 *
 * @typedef {'primary' | 'secondary' | 'tertiary'} Color
 * @description The available colors for the Heading component.
 *
 * @typedef {'regular' | 'medium' | 'semibold' | 'bold'} fontWeight
 * @description The available font weights for the Heading component.
 *
 * @typedef {'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'} Tag
 * @description The available HTML tags for the Heading component.
 */

/**
 * Renders a Heading Tag with a given variant and children.
 *
 * @param {Object} props - The component props.
 * @param {Variant} props.variant - The variant of the button component.
 * @param {fontWeight} props.fontWeight - The font weight of the Heading component.
 * @param {React.ReactNode} props.children - The children to render inside the button component.
 * @param {string} [props.as] - The HTML tag to render.
 * @param {string} [props.className] - The CSS class name to apply to the button component.
 * @param {Color} [props.color] - The color of the Heading component.
 * @returns {React.ReactElement} The rendered button or link component.
 */
function Heading({
  as = "h2",
  variant = "title",
  color = "primary",
  fontWeight,
  className,
  children,
}) {
  const Tag = as;
  const colorClassName = classes[color];
  const variantClassName = classes[variant];
  const fontWeightClassName = fontWeight && classes[fontWeight];
  const combinedClassNames = clsx(
    colorClassName,
    variantClassName,
    fontWeightClassName,
    className
  );
  return <Tag className={combinedClassNames}>{children}</Tag>;
}

export default Heading;
