export const REQUEST_GAMES_LINK =
  "https://docs.google.com/forms/d/e/1FAIpQLSf2joFmtQTIep55TEais5dkP3rl5u_giQjx5SOQ-Nffv0sTZg/viewform";
export const REPORT_ISSUES_LINK =
  "https://docs.google.com/forms/d/e/1FAIpQLSenUCfyLzBi42Xgn4Fsl5nSeBIV6cmZ8Eg7HPuwZ8H1izUQBw/viewform";
export const DISCORD_LINK = "https://discord.gg/pntAKRxaeG";
export const FACEBOOK_LINK =
  "https://www.facebook.com/Epic-Play-100534342864317";
export const INSTAGRAM_LINK = "https://www.instagram.com/epicplay.in/";
export const TWITTER_LINK = "https://twitter.com/epicplay_in";
export const MAILTO_LINK = "mailto:epicplayofficial.in@gmail.com";
export const YOUTUBE_LINK = "https://youtube.com/@epicplay_in";
export const OG_IMAGE_BASE_URL = "https://cfcdn.epicplay.in/epicplay-assets/";
// export const OG_IMAGE_BASE_URL = "https://cfcdn.epicplay.in/epicplay-assets/";
