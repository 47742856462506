import React, { useEffect, useState } from "react";

function useScrollable(
  elementRef,
  options = {
    left: false,
    right: false,
  }
) {
  const [scrollable, setScrollable] = useState({
    left: options.left || false,
    right: options.right || false,
  });

  useEffect(() => {
    const element = elementRef.current;
    if (element) {
      const { scrollWidth, clientWidth, scrollLeft } = element;
      const left = scrollLeft > 0;
      const right = scrollWidth > clientWidth + scrollLeft;
      setScrollable({ left, right });
    }
    const handleScroll = () => {
      const { scrollWidth, clientWidth, scrollLeft } = element;
      const left = scrollLeft > 0;
      const right = scrollWidth > clientWidth + scrollLeft;
      setScrollable({ left, right });
    };
    element.addEventListener("scroll", handleScroll);
    return () => {
      element.removeEventListener("scroll", handleScroll);
    };
  }, [elementRef]);

  return {
    left: scrollable.left,
    right: scrollable.right,
  };
}

export default useScrollable;
